import React  from "react";

import axios  from "axios";
import moment from "moment";

const createAllInvoices = async (setLoading, allMembers, alert) => {
	setLoading(true);

	const now      = moment();
	const response = await axios.get("/cm-zahlungens?_limit=-1").catch(error => console.error(error));

	const spareMembers = new Set();
	for (const invoice of response.data) {
		if (now.diff(moment(invoice.createdAt), "months") >= 12)
			continue;

		spareMembers.add(invoice.cv_member.id);
	}
	
	const members = [...allMembers].filter(user => !spareMembers.has(user.id));

	if (spareMembers.size > 0) {
		alert.error(
			<>
				Für folgende Mitglieder wurde keine Rechnung erstellt, da die letzte Rechnung jünger als 1 Jahr ist: 
				<ul style={{columns : 5}}>
					{allMembers.filter(user => spareMembers.has(user.id)).map((user, index) => <li key={index}>{user.firstName} {user.lastName}</li>)}
				</ul>
			</>,
			{
				timeout : false,
				scroll  : true
			}
		);
	}

	const promises = [];
	let   errors   = 0;

	for (const member of members) {
		promises.push(axios.post("/cm-zahlungens", {
			amount            : member?.cv_plan?.Price,
			cv_plan           : member?.cv_plan,
			cv_payment_method : member?.cv_payment_method,
			cv_member         : member?.id,
			payed             : false
		// eslint-disable-next-line no-loop-func
		}).catch(error => {
			alert.error(`Es gab ein Problem beim Erstellen der Rechnung für ${member.firstName} ${member.lastName}!`);
			errors++;
			console.log(error);
		}));
	}
	await Promise.all(promises);

	const affectedMembers = members.length - errors;
	if (affectedMembers > 0)
		alert.success(`Es wurden ${affectedMembers} Rechnungen erstellt!`);

	setLoading(false);
}

export default createAllInvoices;