import axios from "axios";
import moment from "moment";

import {StrapiDate} from "utils/StrapiUtils";

const createInvoice = async (setLoading, member, alert) => {
	const now        = moment();
	const lastViable = new StrapiDate(now.subtract(1, "year").toDate());
	const response   = await axios.get(`/cm-zahlungens?cv_member=${member.id}&createdAt_gte=${lastViable.toStrapiDate()}&_sort=createdAt:DESC`).catch(error => console.error(error));
	const invoices   = response.data;
	
	if (invoices.length === 0) {
    	setLoading(true);

		try {
			await axios.post("/cm-zahlungens", {
				amount            : member?.cv_plan?.Price,
				cv_plan           : member?.cv_plan,
				cv_payment_method : member?.cv_payment_method,
				cv_member         : member?.id,
				payed             : false
			});
			alert.success(`Neue Rechnung ${member.firstName} ${member.lastName} für wurde erstellt!`);
		} catch (error) {
			console.error(error.response);
			alert.error('Es gab ein Problem beim Erstellen der Rechnung!');
		} finally {
			setLoading(false);
		}
	} else {
		const invoice = invoices[0];
		alert.error(`Die letzte Rechnung von ${invoice.cv_member.firstName} ${invoice.cv_member.lastName} ist jünger als 1 Jahr!`);
	}
}

export default createInvoice;
