import React, {useEffect, useState, useContext} from "react";
import {Routes, Route, NavLink} from "react-router-dom";

import {Kanban, ExclamationTriangle} from "react-bootstrap-icons";

import UserContext from "../../components/UserContext";

import MemberList   from "./components/MemberList";
import PaymentList  from "./components/PaymentList";
import NewProposal  from "./components/NewProposal";
import MailingsMain from "./components/routes/mailings/MailingsMain";
import Signatures   from "./components/routes/mailings/Signatures";


import "./_styles.scss";
import "./_tags.scss";

const ClubManagement = ({type}) => {
    const {permissions}     = useContext(UserContext);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (typeof permissions["users-permissions"] !== "undefined")
            setReady(true);
    }, [permissions])

    if (!ready || permissions["users-permissions"].controllers.user.find.enabled !== true)
        return <></>

    if (type === "nav")
        return (
            <>
                <NavLink className={({isActive}) => isActive ? "active" : ""} to="/Clubmanagement"><Kanban /> <span>Mitgliederverwaltung</span></NavLink>
            </>
        );

    return (
        <>
			{permissions.application?.controllers["cm-zahlungen"].confirm.enabled ? null :
				<section className="alert alert-warning grid ten-ninety">
					<ExclamationTriangle style={{alignSelf : "center", justifySelf : "center", fontSize : "3rem"}} />
					<div>
						<h5>Hinweis</h5>
						Für die Reibungsfreie Abwicklung von Zahlungen via PayPal muss der Zugriff auf die route <code>/cm-zahlungens/confirm</code> für alle Rollen <i>(inklusive <code>Public</code>)</i> freigeschaltet werden.<br />
						Wenn Sie diese Nachricht sehen, informieren Sie bitte einen Mitarbeiter der propeller GmbH.
					</div>
				</section>
			}
            <nav>
                <ul className="nav nav-tabs">
                    <li className="nav-item"><NavLink end className="nav-link" to="/ClubManagement">Mitgliederverzeichnis</NavLink></li>
                    <li className="nav-item"><NavLink end className="nav-link" to="gpayments">Zahlungen</NavLink></li>
                    <li className="nav-item"><NavLink end className="nav-link" to="gnewproposal">Anträge auf Mitgliedschaft</NavLink></li>
                    <li className="nav-item"><NavLink end className="nav-link" to="mailings">Mailings</NavLink></li>
					<span className="spacer"/>
					{
						permissions.application.controllers["cv-signatures"]?.find.enabled ?
						<>
							<li className="nav-item dropdown float-right">
								<a className="nav-link dropdown-toggle" data-toggle="dropdown">Einstellungen</a>
								<ul className="dropdown-menu">
									<li><NavLink end to="mailings/signatures" className="dropdown-item">Signaturen</NavLink></li>
								</ul>
							</li>
						</>
						: ""
					}
                </ul>
            </nav>
            <Routes>
                <Route index element={<MemberList />} />
                <Route exact path="gpayments" element={<PaymentList />} />
                <Route exact path="gnewproposal" element={<NewProposal />} />
				<Route path="mailings/signatures" element={<Signatures />} />
                <Route path="mailings/*" element={<MailingsMain />} />
            </Routes>
        </>
    )
};

export default ClubManagement;
